import Axios from "axios";
import { useLastAppointment } from "context/last-appointment";
import useFeatureManager from "hooks/featureManager";
import Appointment, { isCompleted, isScheduled } from "models/appointment";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { Redirect, useHistory } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { UserActionEvent } from "utils/analytics/events";
import { Breadcrums, Header, Modal } from "../../../components";
import useAnalytics from "../../../hooks/analytics";
import useAuthUser from "../../../hooks/auth-user";
import useLanguage from "../../../hooks/language";
import {
    ConsentModal,
    ExpiredQuestionnaireModal,
    MedicalRecommendationModal,
} from "../../../modals";
import SuggestedMedicalTest from "../../../models/suggested-medical-test";
import User, {
    expiredPendingQuestionnaire,
    hasCompletedQuestionnaireWithoutScheduling,
    hasFollowUpPhoneAppointmentToComplete,
    hasPaidLastQuestionnaire,
    upcomingScreeningAppointment,
} from "../../../models/user";
import ENDPOINTS from "../../../utils/endpoints";
import ROUTES from "../../../utils/routes";
import DateSelection from "./date-selection";
import MedicalEvaluation from "./medical-evaluation";
import Payment from "./payment";
import PersonalInfo from "./personal-info";
import Recommendations from "./recommendations";
import styles from "./screening.module.css";
import UpfrontPayment from "./upfront-payments";
import { FeatureFlagContextProvider } from "../feature-flag-context/featureFlagContext";

interface IScreeningPageProperties {
    breadcrumbsClassName?: string;
}

export interface ScreeningPage {
    content: ReactElement;
    title: string;
}

enum SCREENING_POSITION {
    PERSONAL_INFO = 0,
    MEDICAL_EVALUATION = 1,
    PAYMENT = 2, // page is optional
    RECOMMENDATIONS = 3,
    DATE_SELECTION = 4, // page is optional
}

const getDefaultIndex = (
    user: User | null,
    fromUserQuestionnaireId: number | undefined = undefined,
    isUpfrontPaymentEnabled: boolean
) => {
    if (
        user &&
        hasCompletedQuestionnaireWithoutScheduling(user) &&
        !expiredPendingQuestionnaire(user)
    ) {
        const shouldReturnToPaymentPage =
            (!isUpfrontPaymentEnabled && user.hasToPay) ||
            isUpfrontPaymentEnabled;

        if (
            shouldReturnToPaymentPage &&
            hasPaidLastQuestionnaire(user) &&
            !fromUserQuestionnaireId
        ) {
            return SCREENING_POSITION.RECOMMENDATIONS;
        }
        return SCREENING_POSITION.PAYMENT;
    }
    return SCREENING_POSITION.PERSONAL_INFO;
};

const ScreeningPage: React.FunctionComponent<IScreeningPageProperties> = (
    props: IScreeningPageProperties
) => {
    const { push } = useHistory();
    const { translations } = useLanguage();
    const [authUser, setAuthUser] = useAuthUser();
    const tTitles = translations.sectionSelection.makeScreeningSection;
    const tModal = translations.questionnaireSection.modal;
    const tExpired = translations.expiredSection;
    const { hasToPayLastMissedAppointment } = useLastAppointment();
    const [analytics] = useAnalytics();
    const [featureManager] = useFeatureManager();

    const upcomingAppointment = authUser
        ? upcomingScreeningAppointment(authUser)
        : undefined;

    // Redirect user if he has a follow up phone appointment to complete or need to pay last missed appointment
    if (
        authUser &&
        (hasFollowUpPhoneAppointmentToComplete(authUser) ||
            hasToPayLastMissedAppointment)
    ) {
        return <Redirect to={ROUTES.APPOINTMENTS} />;
    }

    // Refs

    const containerRef = useRef<HTMLDivElement | null>(null);

    // States

    const [isUpfrontPaymentsEnabled, setIsUpfrontPaymentsEnabled] = useState(
        false
    );
    const [
        paymentPageRemainsInBreadcrumbs,
        setPaymentPageRemainsInBreadcrumbs,
    ] = useState(false);
    const [consentModalVisible, setConsentModalVisible] = useState(false);

    // Not to clean but otherwise the modal is shown twice and I don't like it much
    const [
        recommendationModalVisible,
        setRecommendationModalVisible,
    ] = useState(false);
    const [
        expiredQuestionnaireModalVisible,
        setExpiredQuestionnaireModalVisible,
    ] = useState(false);
    // Not to clean but otherwise the modal is shown twice and I don't like it much
    const [
        expiredQuestionnaireModalHasBeenShown,
        setExpiredQuestionnaireModalHasBeenShown,
    ] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(
        getDefaultIndex(authUser, undefined, isUpfrontPaymentsEnabled)
    );
    const [minIndex, setMinIndex] = useState(
        getDefaultIndex(authUser, undefined, isUpfrontPaymentsEnabled)
    );
    const [fromUserQuestionnaireId, setFromUserQuestionnaireId] = useState<
        number | undefined
    >();
    const [selectedMedicalTests, setSelectedMedicalTests] = useState<
        SuggestedMedicalTest[] | undefined
    >();

    const [isLoading, setIsLoading] = useState(false);

    // Effects

    useEffect(() => {
        setIsUpfrontPaymentsEnabled(
            featureManager?.isFeatureFlagEnabled(
                "stripe-upfront-payments",
                "treatment"
            ) ?? false
        );
    }, [featureManager]);

    useEffect(() => {
        if (
            authUser &&
            expiredPendingQuestionnaire(authUser) &&
            !expiredQuestionnaireModalHasBeenShown
        ) {
            setExpiredQuestionnaireModalVisible(true);
            setExpiredQuestionnaireModalHasBeenShown(true);
        }
    }, [authUser]);

    useEffect(() => {
        if (expiredQuestionnaireModalVisible)
            setExpiredQuestionnaireModalHasBeenShown(true);
    }, [expiredQuestionnaireModalVisible]);

    useEffect(() => {
        if (!containerRef.current) return;
        scroll.scrollTo(containerRef.current.offsetTop - 100, {
            duration: 500,
        });
    }, [currentIndex]);

    // Handlers

    const onConsent = () => {
        setConsentModalVisible(false);
        setRecommendationModalVisible(true);
        setCurrentIndex(SCREENING_POSITION.MEDICAL_EVALUATION);

        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.ScreeningPrivacyConsentAccepted);
    };

    const onDeclineConsent = () => {
        setConsentModalVisible(false);
        analytics
            ?.cdp()
            ?.trackEvent(UserActionEvent.ScreeningPrivacyConsentDeclined);
    };

    const onCompleteQuestionnaire = (
        latestUserQuestionnaireData: Required<
            Pick<User, "lastUserQuestionnaire">
        >
    ) => {
        // update authUser without calling /me
        setAuthUser({
            ...(authUser as User),
            ...latestUserQuestionnaireData,
        });
        // next page is either payment or recommendation
        setCurrentIndex(SCREENING_POSITION.MEDICAL_EVALUATION + 1);
        setMinIndex(SCREENING_POSITION.MEDICAL_EVALUATION + 1);
    };

    const onCompletePayment = (canMakePayments: boolean) => {
        // update authUser without calling /me
        setAuthUser({ ...(authUser as User), canMakePayments });
        setPaymentPageRemainsInBreadcrumbs(true);
        setCurrentIndex(SCREENING_POSITION.RECOMMENDATIONS);
        setMinIndex(SCREENING_POSITION.RECOMMENDATIONS);
    };

    const onRestartPayment = () => {
        NotificationManager.info(
            translations.paymentSection.chargeErrorNotification
        );
        // update authUser without calling /me
        setAuthUser({ ...(authUser as User), canMakePayments: false });
        setPaymentPageRemainsInBreadcrumbs(true);
        setCurrentIndex(SCREENING_POSITION.PAYMENT);
        setMinIndex(SCREENING_POSITION.PAYMENT);
    };

    const onRestartQuestionnaireClick = () => {
        // return to personal info and make it possible to no longer see the payment page
        setPaymentPageRemainsInBreadcrumbs(false);
        setFromUserQuestionnaireId(authUser?.lastUserQuestionnaire.id);
        setCurrentIndex(SCREENING_POSITION.PERSONAL_INFO);
        setMinIndex(SCREENING_POSITION.PERSONAL_INFO);

        // update authUser without calling /me
        setAuthUser({
            ...(authUser as User),
            lastUserQuestionnaire: undefined,
        });
    };

    const onSelectMedicalTests = (tests: SuggestedMedicalTest[]) => {
        setSelectedMedicalTests(tests);
        // we are either on the 3rd or 4th page, hence the use of currentIndex
        setMinIndex(currentIndex);
        setCurrentIndex(currentIndex + 1);
    };

    // Network

    const bookAppointment = async (
        datetime: string,
        clinicId: number,
        prepDate: string | null,
        isPrepRefused?: boolean
    ) => {
        if (!authUser?.lastUserQuestionnaire || !selectedMedicalTests) return;

        setIsLoading(true);

        const chosenMedicalTests = selectedMedicalTests.map(
            ({ medicalTest }) => ({
                medicalTestId: medicalTest.id,
            })
        );

        const body = {
            userQuestionnaireId: authUser.lastUserQuestionnaire.id,
            chosenMedicalTests,
            datetime,
            clinicId,
            ...(prepDate && { prepDate }),
            isPrepRefused,
        };

        try {
            await Axios.post<Appointment>(ENDPOINTS.APPOINTMENTS, body);

            // refresh user and go back home
            const { data: userData } = await Axios.get<User>(ENDPOINTS.ME);
            setAuthUser(userData);
            NotificationManager.success(tTitles.appointmentCreated);

            if (isPrepRefused !== undefined && !isPrepRefused) {
                analytics
                    ?.cdp()
                    ?.trackEvent(
                        UserActionEvent.ScreeningPrepAppointmentBooked
                    );
            }

            const eventProperties = {
                appointmentCount:
                    (userData as User).appointments?.filter(
                        (appointment) =>
                            isCompleted(appointment) || isScheduled(appointment)
                    )?.length ?? 0,
            };

            analytics
                ?.cdp()
                ?.trackEvent(
                    UserActionEvent.ScreeningAppointmentBooked,
                    eventProperties
                );
            analytics
                ?.web()
                ?.trackEvent(
                    UserActionEvent.ScreeningAppointmentBooked,
                    eventProperties
                );

            push(ROUTES.HOME);
        } catch (error) {
            const updatedUserData = error?.response?.data?.user;
            if (updatedUserData && updatedUserData.canMakePayments === false) {
                onRestartPayment();
            }
            setIsLoading(false);
        }
    };

    // Rendering

    const pages: ScreeningPage[] = [
        {
            title: tTitles.information,
            content: (
                <div
                    className={styles.page}
                    key={SCREENING_POSITION.PERSONAL_INFO}
                >
                    <PersonalInfo
                        onSubmit={() => setConsentModalVisible(true)}
                    />
                </div>
            ),
        },
        {
            title: tTitles.medicalEvaluation,
            content: (
                <div
                    className={styles.page}
                    key={SCREENING_POSITION.MEDICAL_EVALUATION}
                >
                    <MedicalEvaluation
                        onSubmit={onCompleteQuestionnaire}
                        fromUserQuestionnaireId={fromUserQuestionnaireId}
                    />
                </div>
            ),
        },
    ];

    // Legacy : user has filled a questionnaire and was charged, but didn´t book an appointment
    const wasChargedWithoutScheduling =
        authUser &&
        expiredPendingQuestionnaire(authUser) &&
        hasPaidLastQuestionnaire(authUser);

    // Experiment : Ask RAMQ user for card details (once) so we can charge them no-show fee even if they never log back in
    const willGoThroughPaymentForm =
        authUser && isUpfrontPaymentsEnabled
            ? // keep appointment page in breadcrumbs even after user can make payments value has been updated
              !authUser?.canMakePayments || paymentPageRemainsInBreadcrumbs
            : authUser?.hasToPay;

    // Load simpler screening form when user update questionnaire answers after scheduling
    const willSeeDateSelectionPage = !upcomingAppointment;

    const willSeePaymentPage =
        willSeeDateSelectionPage &&
        willGoThroughPaymentForm &&
        !wasChargedWithoutScheduling;

    if (willSeePaymentPage) {
        pages.push({
            title: tTitles.payment,
            content: (
                <div className={styles.page} key={SCREENING_POSITION.PAYMENT}>
                    {isUpfrontPaymentsEnabled ? (
                        <UpfrontPayment
                            onCompletePayment={onCompletePayment}
                            onSkipPaymentClicked={onCompletePayment}
                        />
                    ) : (
                        <Payment onCompletePayment={onCompletePayment} />
                    )}
                </div>
            ),
        });
    }

    pages.push({
        title: tTitles.recommendations,
        content: (
            <div
                className={styles.page}
                key={SCREENING_POSITION.RECOMMENDATIONS}
            >
                <Recommendations
                    upcomingAppointment={upcomingAppointment}
                    selectedMedicalTests={selectedMedicalTests}
                    onRestartQuestionnaireClick={onRestartQuestionnaireClick}
                    onSelectMedicalTests={onSelectMedicalTests}
                />
            </div>
        ),
    });

    if (willSeeDateSelectionPage) {
        pages.push({
            title: tTitles.date,
            content: (
                <div
                    className={styles.page}
                    key={SCREENING_POSITION.DATE_SELECTION}
                >
                    <FeatureFlagContextProvider
                        flagKey="hide-next-available-date"
                        value="on"
                    >
                        <DateSelection
                            onSubmit={bookAppointment}
                            loading={isLoading}
                        />
                    </FeatureFlagContextProvider>
                </div>
            ),
        });
    }

    return (
        <div ref={containerRef}>
            {/* Modals */}
            <Modal
                title={tModal.consentHeader}
                visible={consentModalVisible}
                onVisibilityChange={setConsentModalVisible}
                className={styles.consentModal}
            >
                <ConsentModal
                    onClose={onDeclineConsent}
                    onConsent={onConsent}
                />
            </Modal>
            <Modal
                title={tModal.recommendationHeader}
                visible={recommendationModalVisible}
                onVisibilityChange={setRecommendationModalVisible}
            >
                <MedicalRecommendationModal
                    onClose={() => setRecommendationModalVisible(false)}
                />
            </Modal>
            <Modal
                title={tExpired.header}
                visible={expiredQuestionnaireModalVisible}
                onVisibilityChange={setExpiredQuestionnaireModalVisible}
            >
                <ExpiredQuestionnaireModal
                    onClose={() => setExpiredQuestionnaireModalVisible(false)}
                />
            </Modal>

            <Header />

            {/* Page content */}

            <Breadcrums
                breadcrumbsClassName={props.breadcrumbsClassName}
                crumTitles={pages.map(({ title }) => title)}
                currentIndex={currentIndex}
                maxIndex={currentIndex}
                minIndex={minIndex}
                onClickIndex={setCurrentIndex}
            />

            <div className={styles.pageHolder}>
                {pages[currentIndex]?.content}
            </div>
        </div>
    );
};

export default ScreeningPage;
