import { useLastAppointment } from "context/last-appointment";
import useFeatureManager from "hooks/featureManager";
import React, { useEffect, useState } from "react";
import { Loader } from "react-feather";
import { Redirect, Route, Switch } from "react-router-dom";
import { Footer, Header, Modal } from "../../components";
import useAuthUser from "../../hooks/auth-user";
import useLanguage from "../../hooks/language";
import { ConfirmPhoneNumberModal } from "../../modals";
import MissedAppointmentPaymentModal from "../../modals/missed-appointment-payment";
import ROUTES from "../../utils/routes";
import bareStyles from "./bare.module.css";
import styles from "./home.module.css";
import MyAppointments from "./my-appointments";
import { MyPrEP } from "./my-prep";
import { Nav } from "./nav";
import ScreeningPage from "./screening";

const HomePage: React.FunctionComponent = () => {
    const [authUser, setAuthUser] = useAuthUser();
    const [featureManager] = useFeatureManager();
    const [isBareHeaderEnabled, setIsBareHeaderEnabled] = useState<
        boolean | undefined
    >(undefined);
    const [canLoadPageContent, setCanLoadPageContent] = useState(false);
    const {
        hasToPayLastMissedAppointment,
        setShowPaymentForm,
    } = useLastAppointment();
    const { translations } = useLanguage();

    // Effects
    useEffect(() => {
        if (!authUser) return;

        setIsBareHeaderEnabled(
            featureManager?.isFeatureFlagEnabled(
                "patient-home-header-rework",
                "on"
            ) ?? false
        );

        // If user tfa pref is sms and phone number is not confirmed, user needs to be prompted
        if (
            authUser.tfaPreference === "sms" &&
            !authUser.phoneNumberConfirmed
        ) {
            setConfirmationModalVisible(true);
        }
    }, [authUser, featureManager]);

    useEffect(() => {
        setCanLoadPageContent(!!authUser && isBareHeaderEnabled !== undefined);
    }, [authUser, isBareHeaderEnabled]);

    const [confirmationModalVisible, setConfirmationModalVisible] = useState(
        false
    );

    // Handlers

    const onConfirmPhoneNumber = () => {
        setConfirmationModalVisible(false);
        if (authUser) setAuthUser({ ...authUser, phoneNumberConfirmed: true });
    };

    const onScreeningLinkClick = (e: React.MouseEvent) => {
        if (hasToPayLastMissedAppointment) {
            e.preventDefault();
            setShowPaymentForm(true);
        }
    };

    let headerTitle = translations.homePage.logo.myDirectoryDecorator;
    let styleModule: { [key: string]: any } = styles;

    if (isBareHeaderEnabled) {
        headerTitle = translations.homePage.logo.headerTitle;
        styleModule = bareStyles;
    }

    return (
        <div>
            {/* Phone confirmation modal */}
            <Modal
                title={translations.phoneConfirmationModal.title}
                visible={confirmationModalVisible}
                onVisibilityChange={setConfirmationModalVisible}
            >
                <ConfirmPhoneNumberModal
                    visible={confirmationModalVisible}
                    phoneNumber={authUser ? authUser.phone : ""}
                    onConfirm={onConfirmPhoneNumber}
                    linkToProfile={true}
                />
            </Modal>

            <Header />

            <MissedAppointmentPaymentModal />

            {canLoadPageContent && (
                <div className={styleModule["header"]}>
                    <div className={styleModule["header-title"]}>
                        <p>{headerTitle}</p>
                    </div>
                    <div className={styleModule["navigation"]}>
                        <Nav
                            styleModule={styleModule}
                            onScreeningLinkClick={onScreeningLinkClick}
                        />
                    </div>
                </div>
            )}

            {canLoadPageContent && (
                <Switch>
                    <Route exact path={`${ROUTES.APPOINTMENTS}`}>
                        <MyAppointments
                            tabHeaderClassName={styleModule["tabs-header"]}
                        />
                    </Route>
                    <Route path={`${ROUTES.SCREENING}`}>
                        <ScreeningPage
                            breadcrumbsClassName={styleModule["breadcrums"]}
                        />
                    </Route>
                    <Route path={ROUTES.PREP}>
                        <MyPrEP
                            tabHeaderClassName={styleModule["tabs-header"]}
                        />
                    </Route>
                    <Redirect to={`${ROUTES.APPOINTMENTS}`} />
                </Switch>
            )}

            {!canLoadPageContent && (
                <div className={styleModule["loader-container"]}>
                    <div className={styleModule["loader"]}>
                        <Loader className="spinorama" />
                        <p>{translations.homePage.loadingInfo}</p>
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
};

export default HomePage;
